import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import PartnerArea from "@containers/partner/layout-04";
import IntroArea from "@components/introarea/layout-1";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import TabsSection from "@components/orangetabs-section/layout-1";
import BoxSection from "@components/BoxSection/layout-three/layout-3";
import IntroAreaOne from "@components/introarea/layout-2";
import CtaArea from "@containers/cta/layout-02";
import FeatureGrid from "@components/feature-grid";
import ContactArea from "@containers/contact-us";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";

const AutomatedSubtitlingPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo title="Premium Automated Subtitling Services by Andovar" 
      description="Automated Subtitling Services - AI-based machine translation solutions, fully trained for television and media applications, providing accurate subtitles."
       />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["automated-subtitling-page-header-data"]} />
        <PartnerArea data={content["automated-subtitling-logo"]} />
        <FeatureGrid data={content["automated-subtitling-features-body"]} />
        <IntroAreaOne data={content["automated-subtitling-intro"]}/>
        <IntroAreaReverse layout={2} data={content["automated-subtitling-media-studio-body"]} />
        <IntroArea data={content["automated-subtitling-translator-body"]} />
        <BoxSection layout={2} data={content["automated-subtitling-project-management-body"]}/>
        <IntroAreaReverse data={content["automated-subtitling-challenges-body"]} />
        <CtaArea data={content["cta-data"]} />
        <TabsSection layout={2} data={content["automated-subtitling-solutions-body"]} />
        <ContactArea Layout={2} />
        <CaseStudy data={caseStudiesData} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query automatedSubtitlingPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "automated-subtitling" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

AutomatedSubtitlingPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default AutomatedSubtitlingPage;
